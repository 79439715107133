import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../templates/layout'
import ArticleBox from '../../components/ArticleBox'
import Hero from '../../components/Hero'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'

type Props = {
	data: {
		markdownRemark: {
			frontmatter: {
				slug: string
				title: string
				description: string
			}
		}
		allPrismicBlog: {
			nodes: {
				uid: string
				data: {
					meta_description: string
					meta_title: string
					title: {
						text: string
					}
					content: {
						text: string
					}
					image: {
						url: string
						alt: string
					}
					category: {
						slug: string
					}
				}
			}[]
		}
	}
}

const Index = ({ data }: Props) => {
	const articles = data.allPrismicBlog.nodes
	const metaTags = data.markdownRemark.frontmatter
	return (
		<Layout border={true}>
			<SEO
				title={metaTags.title}
				description={metaTags.description}
				path={metaTags.title}
			/>
			<Hero
				header='Makaleler'
				description='Ek Hukuk olarak güncel hukuki gelişmeleri takip ediyor,
						değişiklikleri sizlerle paylaşıyor, makaleler kaleme alıyoruz.'
				links={[
					<Link to='/' className='mr-6 text-small text-grey' key='home-link'>
						Ana Sayfa
					</Link>,
					<Link
						to='/makaleler'
						className='font-medium text-small text-grey'
						key='blog-link'
					>
						Blog
					</Link>,
				]}
			/>
			<div className='container'>
				<div className='md:flex md:flex-wrap'>
					{articles.map((article, a) => {
						return (
							<ArticleBox
								key={`${article.data.title}-${a.toString()}`}
								link={`/${article.data.category.slug}/${article.uid}`}
								className='basis-1/3 sm:pr-10 sm:mb-20 mb-10'
								header={article.data.title.text}
								imageSrc={article.data.image.url}
								imageAlt={article.data.image.alt}
								text={article.data.content.text}
							/>
						)
					})}
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query Articles {
		markdownRemark(fileAbsolutePath: { regex: "/articles.md/" }) {
			frontmatter {
				slug
				title
				description
			}
		}
		allPrismicBlog(sort: { fields: data___date }) {
			nodes {
				uid
				data {
					meta_description
					meta_title
					title {
						text
					}
					content {
						text
					}
					category {
						slug
					}
					image {
						url
					}
				}
			}
		}
	}
`
export default Index
